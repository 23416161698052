import './index.css'
import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

const supabase = createClient('https://nipmdlccoesxrvgncpvl.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5pcG1kbGNjb2VzeHJ2Z25jcHZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk2MDkzODcsImV4cCI6MjAzNTE4NTM4N30.4xUe84Q0f1XodZCvFN0gk4ILdcDjMNAFavLTrD6-XSU')

export default function App() {
  const [session, setSession] = useState(null);
  const [data, setData] = useState(null);

  const getSomeData = async () => {
    let { jwt } = "";
    await supabase.auth.getSession().then(session => jwt = session.data.session.access_token);
    fetch('https://api.kafa.chat/dummy/user', {
      headers: {Authorization: `Bearer ${jwt}`}
    })
        .then(resp => resp.text())
        .then(text => setData(text));
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />)
  }
  else {
    return (
        <div>
          <button onClick={getSomeData}>Get some data bitch</button>
          <br/>
          <p>{data}</p>
        </div>)
  }
}